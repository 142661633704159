import { getStorage, ref, uploadBytesResumable, getDownloadURL, getMetadata, deleteObject, listAll  } from "firebase/storage";
import { getFirebaseBackend  as getFirbase  }  from './authUtils';

class CloudStorageBackend {
  constructor() { }

  filePromising = (bucket:string,file: File, path: string, cb) => {
    const out = { "progress": 0, "snapshot": null, "URL": null };
    const storage = getStorage(getFirbase().firebaseApp,"gs://" + bucket);
    const storageRef = ref(storage, path);
    // The main task
    const task = uploadBytesResumable(storageRef, file);

    task.on('state_changed',
      function progress(snapshot) {
        var progress: number = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        out.progress = progress;
        out.snapshot = snapshot;
        cb(out, false);
      },
      function error(err) {
        cb(err, true);
      },
      function complete() {
        getDownloadURL(task.snapshot.ref).then((URL) => {
          out.URL = URL
          cb(out, false);
        });
        
      })
  }

  listAllFiles(bucket: string, type: string, path: string, status) {
    const storage = getStorage(getFirbase().firebaseApp,"gs://" + bucket);
    const listRef = ref(storage, path + type);
    return new Promise((resolve, reject) => {
      const list = [];
      listAll(listRef).then((res) => {
        let count = 0;
        res.items.forEach((itemRef: any) => {
          const fileRef = ref(storage, path + type + "/" + itemRef.name);
          getMetadata(fileRef)
            .then((metadata) => {
              let data = { name: itemRef.name, created_at: metadata.timeCreated, status: status }
              list.push(data)
            })
          count = count + 1;
        })
        setTimeout(function () {
          //console.log("from ", status, " data= ", list);
          resolve({ data: list, length: count });
        }, 1000)

      }).catch((error) => {
        reject(error)
      });
    })
  }

  listAll_(bucket: string, type: string, path: string, status) {
    return new Promise((resolve, reject) => {
      const storage = getStorage(getFirbase().firebaseApp,"gs://" + bucket);
      const listRef = ref(storage, path + type);
      const list = [];
      listAll(listRef).then((res) => {
        res.items.forEach((itemRef: any) => {
          this.getFileCreationDataFromMetaData(itemRef,bucket,type,path,status).then((file:any)=>{
            list.push(file);
          })
        });
        setTimeout(() => {
          //console.log("from ", status, " data= ", list);
          resolve({ data: list, length: list.length });
        }, 2000);
      }).catch((error) => {
        reject(error)
      });
    })
  }

  getFileCreationDataFromMetaData(file: any, bucket: string, type: string, path: string, status) {
    return new Promise((resolve, reject) => {
      const storage = getStorage(getFirbase().firebaseApp,"gs://" + bucket);
      const storageRef = ref(storage, path + type + "/" + file.name);
      getMetadata(storageRef).then((metadata) => {
        const data = { name: file.name, created_at: metadata.timeCreated, size:metadata.size, status: status }
        resolve(data)
      }).catch((error) => {
        reject(error)
      });
    })
  }

  getFileUrl(bucket:string,path: string){
    return new Promise((resolve, reject) => {
      const storage = getStorage(getFirbase().firebaseApp,"gs://" + bucket);
      getDownloadURL(ref(storage, path))   
      .then((url) => {
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }
  deleteFile = (bucket:string,path: string) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage(getFirbase().firebaseApp,"gs://" + bucket);
      const storageRef = ref(storage, path);
      deleteObject(storageRef).then(() => {
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    })


  }
}


let _CloudStorageBackend = new CloudStorageBackend();


const getCloudStorageBackend = () => {
  return _CloudStorageBackend;
};

export { getCloudStorageBackend };